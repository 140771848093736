import * as types from './mutation-types';

const mutations = {
  [types.HP_ERROR_LOG_DETAILS](state, hpErrorLog) {
    state.hpErrorLog = hpErrorLog;
  },

  // [types.STORYPHONE_DETAILS_BY_ID](state, storyPhoneById) {
  //   state.storyPhoneById = storyPhoneById;
  // },
  // [types.DELETED_STORYPHONE](state, deletedStoryPhone) {
  //   state.deletedStoryPhone = deletedStoryPhone;
  // },
  // [types.STORYPHONE_MODELS](state, storyPhoneModels) {
  //   state.storyPhoneModels = storyPhoneModels;
  // },

};

export default mutations;
