import PushNotificationService from '@/services/PushNotificationService';
import * as types from './mutation-types';

const ent = require('ent');

const pushNotificationService = new PushNotificationService();

export const fetchAllUsersWithFcmToken = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    console.log(data);
    pushNotificationService
      .getAllUsersWithFcmToken(data)
      .then((response) => {
        commit(types.USER, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

export const fetchAllPushNotifications = ({ commit }, data) => new Promise(
  (resolve, reject) => {
    pushNotificationService
      .getAllPushNotifications(data)
      .then((response) => {
        commit(types.PUSH_NOTIFICATIONS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

// export const fetchUserById = ({ commit }, Data) => new Promise(
//   (resolve, reject) => {
//     userService
//       .getUserById(Data)
//       .then((response) => {
//         commit(types.SINGLE_USER, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchUserGroups = ({ commit }) => new Promise(
//   (resolve, reject) => {
//     userService
//       .getUserGroups()
//       .then((response) => {
//         commit(types.USER_GROUPS, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchUserProfile = ({ commit }) => new Promise(
//   (resolve, reject) => {
//     userService
//       .getUserProfile()
//       .then((response) => {
//         commit(types.USER_PROFILE, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchDeletedUser = ({ commit }, Data) => new Promise(
//   (resolve, reject) => {
//     userService
//       .getAllDeletedUser(Data)
//       .then((response) => {
//         commit(types.DELETED_USER, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );
