/* eslint-disable import/extensions */
import { createStore } from 'vuex';
import story from './story';
import user from './user';
import playlist from './playlist';
import types from './types';
import storyPhone from './storyPhone';
import shield from './shield';
import cmsStory from './cmsStory';
import language from './language';
import cmspage from './cmspage';
import cmsWidgets from './cmsWidgets';
import firmware from './firmware';
import cmsProduct from './cmsProduct';
import hpErrorLog from './hpErrorLog';
import pushNotification from './pushNotification';

const store = createStore({
  modules: {
    story,
    user,
    playlist,
    types,
    storyPhone,
    shield,
    cmsStory,
    language,
    cmspage,
    cmsWidgets,
    firmware,
    cmsProduct,
    hpErrorLog,
    pushNotification,
  },
});

export default store;
