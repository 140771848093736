<template lang="html">
  <div v-if="isLoading"><Loader /></div>
  <DashboardTemplate>
    <div class="row p-3">
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <h1><i class="bi bi-bug"></i> Hp Bug Report</h1>
          </div>
          <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
            <div class="row justify-content-end">
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">Search</label>
                <input
                  type="text"
                  class="form-control rounded-5"
                  placeholder="Search"
                  v-model="searchUser"
                  v-on:keyup.enter="searchClick()"
                />
              </div>
              <div class="col-auto tooltip-wrap">
                <button
                  type="submit"
                  class="btn btn-outline-primary mb-3 rounded-5"
                  @click="searchClick()"
                >
                  <i class="bi bi-search"></i>
                  <span class="tooltip">Search Here</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="successMsg" class="card text-success text-center border-success rounded-5 mb-3">
          <div class="card-body">{{ successMsg }}</div>
        </div>
        <ErrorDisplay :error="errorMsg" message="Unable to update user"/>
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-text">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Status Code</th>
                      <th>Device ID</th>
                      <th>Shield ID</th>
                      <th>Email Address</th>
                      <th>Date & Time</th>
                      <!-- <th>Staff Status</th> -->
                      <th>More</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="allHpErrorLogs?.total === 0">
                      <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                        Not Found
                      </td>
                    </tr>
                    <tr v-else v-for="(item, index) in allHpErrorLogs.items" :key="index">
                      <td>{{ item.status_code }}</td>
                      <td>{{ item.device_id }}</td>
                      <td>{{ item.shield_id }}</td>
                      <td>
                       {{ item?.user?.email }}
                      </td>
                      <td>{{ item.datetime_of_error }}</td>
                      <!-- <td>{{ item.is_staff }}</td> -->
                      <td>
                          <button
                            class="btn bi bi-pencil-square btn-outline-primary rounded-5"
                            data-bs-toggle="modal"
                            data-bs-target="#hpErrorLogModal"
                            @click="getHpErrorLogDetails(item?.error_message)"
                          ></button>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <VuejsPaginateNext
            v-model="page"
            :page-count="pageCount"
            :click-handler="pageClick"
            :margin-pages="2"
            :page-range="5"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'prev-item'"
            :prev-link-class="'page-link prev-link-item'"
            :next-class="'next-item'"
            :next-link-class="'page-link next-link-item'"
            :break-view-class="'break-view'"
            :break-view-link-class="'break-view-link'"
          ></VuejsPaginateNext>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <div
    class="modal fade"
    id="hpErrorLogModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="hpErrorLogModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="hpErrorLogModalLabel">Error Message</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">{{ hpErrorMsg }}</div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import Loader from '@/components/commons/Loader.vue';
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

export default {
  name: 'HpErrorLogPage',
  props: [],
  components: {
    DashboardTemplate,
    Loader,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const routePath = ref('');
    routePath.value = route.path;
    const userId = ref('');
    const isLoading = ref(false);
    const currentOffset = ref(0);
    const perPageUser = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const searchUser = ref('');
    const hpErrorMsg = ref('');
    const successMsg = ref('');
    const errorMsg = ref('');
    const searchData = {
      searchUser,
      currentOffset,
      perPageUser,
    };
    isLoading.value = true;
    store.dispatch('fetchAllHpErrorLog', searchData)
      .then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });

    const getHpErrorLogDetails = (msg) => {
      hpErrorMsg.value = msg;
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchUser,
        currentOffset,
        perPageUser,
      };
      isLoading.value = true;
      store.dispatch(
        'fetchAllHpErrorLog',
        search,
      ).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPageUser.value * (pageNum - 1);
      const Data = {
        searchUser,
        currentOffset,
        perPageUser,
      };
      store.dispatch('fetchAllHpErrorLog', Data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPageUser.value);

        isLoading.value = false;
      });
    };

    const allHpErrorLogs = computed(() => store.getters.getAllHpErrorLog);

    console.log(allHpErrorLogs);
    return {
      allHpErrorLogs,
      getHpErrorLogDetails,
      isLoading,
      searchClick,
      searchUser,
      currentOffset,
      perPageUser,
      pageCount,
      page,
      pageClick,
      routePath,
      successMsg,
      errorMsg,
      hpErrorMsg,
    };
  },
};
</script>
