import HpErrorLogService from '@/services/HpErrorLogService';
import * as types from './mutation-types';

const hpErrorLogService = new HpErrorLogService();

export const fetchAllHpErrorLog = ({ commit }, Data) => new Promise(
  (resolve, reject) => {
    hpErrorLogService
      .getAllHpErrorLog(Data)
      .then((response) => {
        commit(types.HP_ERROR_LOG_DETAILS, response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  },
);

// export const fetchStoryPhoneById = ({ commit }, Data) => new Promise(
//   (resolve, reject) => {
//     storyPhoneService
//       .getStoryPhoneDetailsById(Data)
//       .then((response) => {
//         commit(types.STORYPHONE_DETAILS_BY_ID, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchDeletedStoryPhone = ({ commit }, Data) => new Promise(
//   (resolve, reject) => {
//     storyPhoneService
//       .getAllDeletedStoryPhone(Data)
//       .then((response) => {
//         commit(types.DELETED_STORYPHONE, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );

// export const fetchStoryPhoneModels = ({ commit }) => new Promise(
//   (resolve, reject) => {
//     storyPhoneService
//       .getStoryPhoneModels()
//       .then((response) => {
//         commit(types.STORYPHONE_MODELS, response.data);
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   },
// );
