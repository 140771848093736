import { IsAuthenticated, IsUser } from '../middlewares/index';
import Home from '../pages/Home.vue';
import About from '../pages/About.vue';
import Playlists from '../pages/Playlists.vue';
import Shields from '../pages/Shields.vue';
import Dashboard from '../pages/Dashboard.vue';
import Stories from '../pages/Stories.vue';
import HeadPhones from '../pages/HeadPhones.vue';
import Users from '../pages/Users.vue';
import Login from '../pages/Login.vue';
import ShieldsType from '../pages/ShieldsType.vue';
import StoryType from '../pages/StoryType.vue';
import PlaylistType from '../pages/PlaylistType.vue';
import AddNewUser from '../pages/AddNewUser.vue';
import EditUser from '../pages/EditUser.vue';
import EditProfileUser from '../pages/UserProfileEdit.vue';
import DeletedUser from '../pages/DeletedUsers.vue';
import DeletedStories from '../pages/DeletedStories.vue';
import DeletedShields from '../pages/DeletedShields.vue';
import DeletedPlaylists from '../pages/DeletedPlaylists.vue';
import DeletedHeadPhones from '../pages/DeletedHeadPhones.vue';
import AddNewHeadphones from '../pages/AddNewHeadphones.vue';
import EditHeadphone from '../pages/EditHeadphone.vue';
import CmsStories from '../pages/CmsStories.vue';
import AddNewStory from '../pages/AddNewStory.vue';
import AddNewPlaylist from '../pages/AddNewPlaylist.vue';
import AddNewShield from '../pages/AddNewShield.vue';
import EditStory from '../pages/EditStory.vue';
import EditPlaylist from '../pages/EditPlaylist.vue';
import EditShield from '../pages/EditShield.vue';
import CmsPage from '../pages/CmsPage.vue';
import CmsDetails from '../pages/CmsPageDetails.vue';
import AddCmsPage from '../pages/AddCmsPage.vue';
import CmsWidgets from '../pages/CmsWidgets.vue';
import AddCmsWidget from '../pages/AddCmsWidget.vue';
import CmsWidgetDetails from '../pages/CmsWidgetDetails.vue';
import FirmWare from '../pages/FirmWare.vue';
import AddFirmware from '../pages/AddFirmware.vue';
import FirmWareDetail from '../pages/FirmWareDetail.vue';
import Quill from '../pages/Quill.vue';
import Languages from '../pages/Languages.vue';
import CmsStoryShield from '../pages/CmsStoryShield.vue';
import CreateProduct from '../pages/CreateProduct.vue';
import CmsStoryphones from '../pages/CmsStoryphones.vue';
import CmsZenShield from '../pages/CmsZenShield.vue';
import HpErrorLogPage from '../pages/HpErrorLogPage.vue';
import PushNotification from '../pages/PushNotification.vue';

export default [
  {
    path: '/',
    beforeEnter: IsUser, // redirect to Login
    component: Login,
  },
  {
    path: '/home',
    component: Home,
    name: 'home',
  },
  {
    path: '/about',
    beforeEnter: IsAuthenticated, // restricted routes
    component: About,
  },
  {
    path: '/dashboard',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Dashboard,
  },
  {
    path: '/playlists',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Playlists,
  },
  {
    path: '/shields',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Shields,
  },
  {
    path: '/stories',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Stories,
  },
  {
    path: '/headphones',
    beforeEnter: IsAuthenticated, // restricted routes
    component: HeadPhones,
  },
  {
    path: '/users',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Users,
  },
  {
    path: '/shields-type',
    beforeEnter: IsAuthenticated, // restricted routes
    component: ShieldsType,
  },
  {
    path: '/story-type',
    beforeEnter: IsAuthenticated, // restricted routes
    component: StoryType,
  },
  {
    path: '/playlist-type',
    beforeEnter: IsAuthenticated, // restricted routes
    component: PlaylistType,
  },
  {
    path: '/new-user',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddNewUser,
  },
  {
    path: '/profile-edit/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditProfileUser,
  },
  {
    path: '/deleted-user',
    beforeEnter: IsAuthenticated, // restricted routes
    component: DeletedUser,
  },
  {
    path: '/deleted-story',
    beforeEnter: IsAuthenticated, // restricted routes
    component: DeletedStories,
  },
  {
    path: '/deleted-shield',
    beforeEnter: IsAuthenticated, // restricted routes
    component: DeletedShields,
  },
  {
    path: '/deleted-playlist',
    beforeEnter: IsAuthenticated, // restricted routes
    component: DeletedPlaylists,
  },
  {
    path: '/deleted-headphone',
    beforeEnter: IsAuthenticated, // restricted routes
    component: DeletedHeadPhones,
  },
  {
    path: '/cms-story',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsStories,
  },
  {
    path: '/add-story',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddNewStory,
  },
  {
    path: '/add-playlist',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddNewPlaylist,
  },
  {
    path: '/add-shield',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddNewShield,
  },
  {
    path: '/add-headphone',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddNewHeadphones,
  },
  {
    path: '/edit-story/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditStory,
  },
  {
    path: '/edit-playlist/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditPlaylist,
  },
  {
    name: 'edit-headphone',
    path: '/edit-headphone/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditHeadphone,
  },
  {
    name: 'edit-shield',
    path: '/edit-shield/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditShield,
  },
  {
    name: 'edit-user',
    path: '/edit-user/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: EditUser,
  },
  {
    name: 'cms-page',
    path: '/cms-page',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsPage,
  },
  {
    path: '/cms-details/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsDetails,
    name: 'CmsDetails',
  },
  {
    path: '/cms-add',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddCmsPage,
    name: 'AddCmsPage',
  },

  {
    name: 'cmsWidgets',
    path: '/cms-widgets',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsWidgets,
  },
  {
    path: '/cms-widget',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddCmsWidget,
    name: 'AddCmsWidget',
  },
  {
    path: '/cms-widget-details/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsWidgetDetails,
    name: 'CmsWidgetDetails',
  },
  {
    name: 'firmware',
    path: '/firm-ware',
    beforeEnter: IsAuthenticated, // restricted routes
    component: FirmWare,
  },
  {
    name: 'AddFirmware',
    path: '/add-firmware',
    beforeEnter: IsAuthenticated, // restricted routes
    component: AddFirmware,
  },
  {
    name: 'FirmWareDetail',
    path: '/firm-detail/:id',
    beforeEnter: IsAuthenticated, // restricted routes
    component: FirmWareDetail,
  },

  {
    name: 'Languages',
    path: '/languages',
    beforeEnter: IsAuthenticated, // restricted routes
    component: Languages,
  },
  {
    name: 'Quill',
    path: '/quill',
    component: Quill,
  },
  {
    name: 'CmsStoryShield',
    path: '/cms-storyshield',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsStoryShield,
  },
  {
    name: 'CreateProduct',
    path: '/create-product',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CreateProduct,
  },
  {
    name: 'PushNotification',
    path: '/push-notification',
    beforeEnter: IsAuthenticated, // restricted routes
    component: PushNotification,
  },
  {
    name: 'CmsStoryphones',
    path: '/cms-storyphones',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsStoryphones,
  },
  {
    name: 'CmsZenShield',
    path: '/cms-zenshield',
    beforeEnter: IsAuthenticated, // restricted routes
    component: CmsZenShield,
  },
  {
    name: 'HpErrorLogPage',
    path: '/hp-error-log-page',
    beforeEnter: IsAuthenticated, // restricted routes
    component: HpErrorLogPage,
  },
];
