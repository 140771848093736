<template lang="">
  <DashboardTemplate>
    <div v-if="isLoading"><Loader /></div>
    <div class="col pt-3 fade-in">
      <div class="row p-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <h1><i class="bi bi-megaphone"></i> Push Notifications</h1>
            </div>
            <div class="col-12 bg-white mb-3 pt-3 mt-2 rounded-5 shadow-sm">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <label for="inputPassword2" class="visually-hidden">Search</label>
                  <input
                    type="text"
                    class="form-control rounded-5"
                    placeholder="Search"
                    v-model="searchPushNotification"
                    v-on:keyup.enter="searchClick()"
                  />
                </div>
                <div class="col-auto tooltip-wrap">
                  <button
                    type="submit"
                    class="btn btn-outline-primary mb-3 rounded-5"
                    @click="searchClick()"
                  >
                    <i class="bi bi-search"></i>
                    <span class="tooltip">Search Here</span>
                  </button>
                </div>
                <div class="col-auto tooltip-wrap">
                  <a @click="addMessage()" class="btn btn-outline-success me-3 rounded-5"
                    ><i class="bi bi-chat-text"></i>
                    <span class="tooltip">Add New</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="successMsg"
            class="card text-success text-center border-success rounded-5 mb-3"
          >
            <div class="card-body">{{ successMsg }}</div>
          </div>
          <ErrorDisplay :error="errorMsg" message="Unable to update type" />
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="card-text">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>Title</th>
                        <th>Body</th>
                        <th>Created At (HKT)</th>
                        <th>Redirect</th>
                        <th>Image</th>
                        <th>Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="pushNotificatons?.total === 0">
                        <td scope="col" colspan="9" class="text-center text-primary pt-3 pb-3">
                          Not Found
                        </td>
                      </tr>
                      <tr
                        class="text-center"
                        v-else
                        v-for="(token, index) in pushNotificatons.items"
                        :key="index"
                      >
                        <!-- <td>{{ type.id }}</td> -->
                        <td>{{ token.title }}</td>
                        <td>{{ token.body }}</td>
                        <td>
                          {{
                            new Date(token.created_at).toLocaleString('en-US', {
                              timeZone: 'Asia/Hong_Kong',
                            })
                          }}
                        </td>
                        <td>{{ formatRedirect(token.data) }}</td>
                        <td>
                          <template v-if="token.image">
                            <img
                              :src="token.image"
                              alt="Notification Image"
                              style="max-width: 100%; height: auto"
                            />
                          </template>
                          <span v-else>No Image</span>
                        </td>
                        <td>{{ token.created_by?.email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <VuejsPaginateNext
              v-model="page"
              :page-count="pageCount"
              :click-handler="pageClick"
              :margin-pages="2"
              :page-range="3"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link prev-link-item'"
              :next-class="'next-item'"
              :next-link-class="'page-link next-link-item'"
              :break-view-class="'break-view'"
              :break-view-link-class="'break-view-link'"
            ></VuejsPaginateNext>
          </div>
        </div>
      </div>
    </div>
  </DashboardTemplate>

  <!-- add Modal -->
  <div
    class="modal fade"
    id="shieldTypeAddModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="shieldTypeAddModalLabel"
    aria-hidden="true"
    :class="{ showModal: modalShow, show: modalShow }"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shieldTypeAddModalLabel">Message</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
            @click="closeModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading"><Loader /></div>
          <div class="container g-3">
            <div class="row">
              <div class="col-md-6">
                <label for="" class="form-label">Title</label>
                <input type="text" class="form-control" v-model="message.title" />
                <span class="text-danger" v-if="v$.title.$error">
                  {{ v$.title.$errors[0].$message }}*
                </span>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Message Body</label>
                <textarea
                  class="form-control"
                  v-model="message.body"
                  rows="4"
                  style="resize: vertical"
                ></textarea>
                <span class="text-danger" v-if="v$.body.$error">
                  {{ v$.body.$errors[0].$message }}*
                </span>
              </div>
              <div class="col-md-6">
                <label for="" class="form-label">Image</label>
                <div class="input-group">
                  <div class="input-group-text">
                    <div v-if="imageURL">
                      <img :src="imageURL" alt="" style="width: 80px" />
                    </div>
                    <div v-else class="text-danger">Preview</div>
                  </div>
                  <input
                    type="file"
                    class="form-control-file"
                    id="user-image-file"
                    accept="image/*"
                    hidden="hidden"
                  />
                  <button
                    class="btn btn-primary form-control"
                    type="button"
                    id="custom-button"
                    @click="imageFile()"
                  >
                    <span>Upload <i class="bi bi-upload" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <h5>Redirect to</h5>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="message.redirectTo"
                    value="home"
                    id="redirect-home"
                  />
                  <label class="form-check-label" for="redirect-home">Home</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="message.redirectTo"
                    value="shop"
                    id="redirect-shop"
                  />
                  <label class="form-check-label" for="redirect-shop">Shop</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="message.redirectTo"
                    value="customUrl"
                    id="redirect-customUrl"
                  />
                  <label class="form-check-label" for="redirect-web">Custom Url</label>
                </div>

                <!-- Show input field when "web" is selected -->
                <div v-if="message.redirectTo === 'customUrl'" class="mt-2">
                  <input
                    type="text"
                    v-model="message.customUrl"
                    class="form-control"
                    placeholder="Enter custom URL"
                  />
                </div>
                <span class="text-danger" v-if="v$.customUrl.$error">
                  {{ v$.customUrl.$errors[0].$message }}*
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal()"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            @click="sendMessage()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
/* eslint-disable */
import DashboardTemplate from '@/components/templates/DashboardTemplate.vue';
import { useStore } from 'vuex';

import {
  ref, computed, onMounted, reactive,
} from 'vue';
import {
  required, email, helpers, between,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import VuejsPaginateNext from '@/components/partials/Paginate.vue';
import Loader from '@/components/commons/Loader.vue';
import PushNotificationService from '@/services/PushNotificationService';
import ErrorDisplay from '@/components/partials/ErrorDisplay.vue';

const pushNotificationService = new PushNotificationService();

export default {
  name: 'PushNotification',
  props: [],
  components: {
    Loader,
    DashboardTemplate,
    VuejsPaginateNext,
    ErrorDisplay,
  },
  setup(props) {
    const imageFileBtn = ref(null);
    onMounted(() => {
      imageFileBtn.value = document.getElementById('user-image-file');
    });

    const store = useStore();
    const searchPushNotification = ref('');
    const imageURL = ref('');

    const isLoading = ref(false);
    const modalShow = ref(false);
    const successMsg = ref('');
    const errorMsg = ref('');
    const currentOffset = ref(0);
    const perPagePushNotification = ref(10);
    const pageCount = ref(0);
    const page = ref(1);
    const message = reactive({
      title: '',
      body: '',
      image: '',
      redirectTo: 'home',
      customUrl: '',
    });



    isLoading.value = true;

    const rules = computed(() => {
  return {
    title: { required: helpers.withMessage('Please enter title', required) },
    body: { required: helpers.withMessage('Please enter a body', required) },
    customUrl: message.redirectTo === 'customUrl' ? {
      required: helpers.withMessage('Please enter a URL', required),
    } : {}
  };
});

    const v$ = useVuelidate(rules, message);

    const closeModal = () => {
      modalShow.value = false;
      message.title = '';
      message.body = '';
      message.customUrl = '';
      message.redirectTo = 'home';
      imageURL.value = '';
    };

    const data = {
      searchPushNotification,
      currentOffset,
      perPagePushNotification,
    };

    store.dispatch('fetchAllPushNotifications', data).then((response) => {
      pageCount.value = Math.ceil(response.data.total / perPagePushNotification.value);
      isLoading.value = false;
    });

    const pushNotificatons = computed(() => store.getters.getAllPushNotificatons);
    const sendMessage = async () => {
      console.log(message);

      const result = await v$.value.$validate();
      if (result) {
        isLoading.value = true;
        const formData = new FormData();
        console.log(message.redirectTo);
        formData.append('title', message.title.trim());
        formData.append('body', message.body.trim());
        formData.append('image', message.image);
        formData.append('data', JSON.stringify({ redirectTo: message.redirectTo, customUrl: message.customUrl }));
        pushNotificationService.sendMessage(formData).then(() => {
          message.title = '';
          message.body = '';
          message.customUrl = '';
          message.redirectTo = 'home';
          imageURL.value = '';
          v$.value.$reset();
          modalShow.value = false;
          store.dispatch('fetchAllPushNotifications', data).then((response) => {
            pageCount.value = Math.ceil(response.data.total / perPagePushNotification.value);
            isLoading.value = false;
            successMsg.value = ' Push Notification Send Successfully';
            setTimeout(() => {
              successMsg.value = '';
            }, 3000);
          });
        })
          .catch((error) => {
            modalShow.value = false;
            isLoading.value = false;
            errorMsg.value = error?.data;
            setTimeout(() => {
              errorMsg.value = '';
            }, 3000);
          });
      }
    };

    const pageClick = (pageNum) => {
      isLoading.value = true;

      currentOffset.value = perPagePushNotification.value * (pageNum - 1);

      store.dispatch('fetchAllPushNotifications', data).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPagePushNotification.value);
        isLoading.value = false;
      });
    };

    const addMessage = () => {
      modalShow.value = true;
    };

    const imageListener = () => {
      if (imageFileBtn.value) {
        const file = imageFileBtn.value.files[0];
        message.image = file;
        const blob = new Blob([file], { type: file.type });
        imageURL.value = URL.createObjectURL(blob);
        imageFileBtn.value.value = '';
      } else {
        console.log('no file selected');
      }
    };

    const imageFile = () => {
      imageFileBtn.value = document.getElementById('user-image-file');
      imageFileBtn.value.click();
      imageFileBtn.value.addEventListener('change', imageListener);
    };

    const searchClick = () => {
      currentOffset.value = 0;
      const search = {
        searchPushNotification,
        currentOffset,
        perPagePushNotification,
      };
      isLoading.value = true;
      store.dispatch('fetchAllPushNotifications', search).then((response) => {
        pageCount.value = Math.ceil(response.data.total / perPagePushNotification.value);
        isLoading.value = false;
      });
    };

    const formatRedirect = (data) => {
      try {
        const parsedData = JSON.parse(data);
        if (parsedData.redirectTo === "customUrl") {
          return parsedData.customUrl;
        }
        return parsedData.redirectTo;
      } catch (error) {
        return data;
      }
    }

    return {
      isLoading,
      pushNotificatons,
      //   allTypeLists,
      message,
      sendMessage,
      imageFile,
      imageURL,
      searchPushNotification,
      searchClick,
      v$,
      closeModal,
      modalShow,
      addMessage,
      successMsg,
      errorMsg,
      pageClick,
      currentOffset,
      perPagePushNotification,
      pageCount,
      page,
      formatRedirect
    };
  },
};
</script>
