<template lang="html">
  <div id="sidebar">
    <div class="sidebar-wrapper active bg-primary">
      <div class="sidebar-header position-relative">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <div class="logo">
            <a href="index.html">
              <img src="@/assets/images/logo-black.png" alt="Logo" />
            </a>
          </div> -->
          <div class="sidebar-toggler x">
            <a href="#" class="sidebar-hide d-xl-none d-block"><i class="bi bi-x bi-middle"></i></a>
          </div>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul class="menu">
          <li class="sidebar-item" :class="{ active: routePath === '/dashboard' }">
            <router-link to="/dashboard" class="sidebar-link">
              <i class="bi bi-grid-fill"></i>
              <span>Dashboard</span>
            </router-link>
          </li>

          <li class="sidebar-title">Entity Management</li>

          <li class="sidebar-item" :class="{ active: routePath === '/headphones' }">
            <router-link to="/headphones" class="sidebar-link">
              <i class="bi bi-headphones"></i>
              <span>HeadPhones</span>
            </router-link>
          </li>

          <li class="sidebar-item" :class="{ active: routePath === '/shields' }">
            <router-link to="/shields" class="sidebar-link">
              <i class="bi bi-shield"></i>
              <span>Shields</span>
            </router-link>
          </li>

          <li class="sidebar-item" :class="{ active: routePath === '/playlists' }">
            <router-link to="/playlists" class="sidebar-link">
              <i class="bi bi-music-note-list"></i>
              <span>Playlists</span>
            </router-link>
          </li>

          <li class="sidebar-item" :class="{ active: routePath === '/stories' }">
            <router-link to="/stories" class="sidebar-link">
              <i class="bi bi-music-note-beamed"></i>
              <span>Stories</span>
            </router-link>
          </li>
          <li class="sidebar-title">Type Management</li>

          <li class="sidebar-item" :class="{ active: routePath === '/story-type' }">
            <router-link to="/story-type" class="sidebar-link">
              <i class="bi bi-braces-asterisk"></i>
              <span>Story Types</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/playlist-type' }">
            <router-link to="/playlist-type" class="sidebar-link">
              <i class="bi bi-braces-asterisk"></i>
              <span>Playlist Types</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/shields-type' }">
            <router-link to="/shields-type" class="sidebar-link">
              <i class="bi bi-braces-asterisk"></i>
              <span>Shields Types</span>
            </router-link>
          </li>
          <!-- <li class="sidebar-item">
            <a href="" class="sidebar-link">
              <i class="bi bi-stack"></i>
              <span>Firmwares</span>
            </a>
          </li> -->

          <li class="sidebar-title">User Management</li>

          <li class="sidebar-item" :class="{ active: routePath === '/users' }">
            <router-link to="/users" class="sidebar-link">
              <i class="bi bi-people"></i>
              <span>Users</span>
            </router-link>
          </li>

          <!-- <li class="sidebar-item">
            <a href="" class="sidebar-link">
              <i class="bi bi-person-fill-lock"></i>
              <span>Roles</span>
            </a>
          </li> -->
          <li class="sidebar-title">Content Management</li>
          <!-- <li class="sidebar-item" :class="{ active: routePath === '/cms-story' }">
            <router-link to="/cms-story" class="sidebar-link">
              <i class="bi bi-music-note-beamed"></i>
              <span>Stories</span>
            </router-link>
          </li> -->

          <li class="sidebar-item" :class="{ active: routePath === '/cms-page' }">
            <router-link to="/cms-page" class="sidebar-link">
              <i class="bi bi-book"></i>
              <span>CMS Pages</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/cms-widgets' }">
            <router-link to="/cms-widgets" class="sidebar-link">
           <i class="bi bi-book"></i>
              <span>CMS Widgets</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/firm-ware' }">
            <router-link to="/firm-ware" class="sidebar-link">
              <i class="bi bi-music-note-beamed"></i>
              <span>Firmware</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/cms-storyshield' }">
            <router-link to="/cms-storyshield" class="sidebar-link">
              <i class="bi bi-music-note-list"></i>
              <span>CMS StoryShield</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/cms-zenshield' }">
            <router-link to="/cms-zenshield" class="sidebar-link">
              <i class="bi bi-music-note-list"></i>
              <span>CMS ZenShield</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/cms-storyphones' }">
            <router-link to="/cms-storyphones" class="sidebar-link">
              <i class="bi bi-headphones"></i>
              <span>CMS Storyphones</span>
            </router-link>
          </li>
          <li class="sidebar-item" :class="{ active: routePath === '/create-product' }">
            <router-link to="/create-product" class="sidebar-link">
              <i class="bi bi-bag"></i>
              <span> Create Product</span>
            </router-link>
          </li>
          <!-- <li class="sidebar-item" :class="{ active: routePath === '/firm-ware' }">
            <router-link to="/languages" class="sidebar-link">
              <i class="bi bi-music-note-beamed"></i>
              <span>Languages</span>
            </router-link>
          </li> -->
          <!-- <li class="sidebar-title">Reports</li>

          <li class="sidebar-item">
            <a href="" class="sidebar-link">
              <i class="bi bi-database-down"></i>
              <span>Usage Statistics</span>
            </a>
          </li> -->

          <!-- <li class="sidebar-item">
            <a href="user-statistics.html" class="sidebar-link">
              <i class="bi bi-database-down"></i>
              <span>User Statistics</span>
            </a>
          </li> -->

          <!-- <li class="sidebar-item">
            <a @click="logOut()" class="sidebar-link">
              <i class="bi bi-x-octagon"></i>
              <span>Logout</span>
            </a>
          </li> -->
          <li class="sidebar-title">Reports</li>
          <li class="sidebar-item" :class="{ active: routePath === '/hp-error-log-page' }">
            <router-link to="/hp-error-log-page" class="sidebar-link">
              <i class="bi bi-bug"></i>
              <span>HP Error Log</span>
            </router-link>
          </li>
          <li class="sidebar-title">Promotion Management</li>
          <li class="sidebar-item" :class="{ active: routePath === '/push-notification' }">
            <router-link to="/push-notification" class="sidebar-link">
              <i class="bi bi-megaphone"></i>
              <span> Push Notifications</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import { useRouter, useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import {
  ref, computed, onMounted, reactive, watch,
} from 'vue';

const { cookies } = useCookies({ secure: true });

export default {
  name: 'SideBar',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const routePath = ref('');
    routePath.value = route.path;

    const logOut = () => {
      cookies.remove('storyPhoneAdminCookie');
      cookies.remove('storyPhoneAdminRefreshCookie');
      localStorage.removeItem('storyPhonesUser');
      router.push('/');
    };

    return {
      logOut, routePath,
    };
  },

};
</script>

<style scoped>
.router-link-active {
  font-weight: 600;
}
.dropdown-divider {
  color: #fff;
}
</style>
