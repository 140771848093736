import * as types from './mutation-types';

const mutations = {
  [types.USER](state, user) {
    state.user = user;
  },
  [types.PUSH_NOTIFICATIONS](state, pushNotificatons) {
    state.pushNotificatons = pushNotificatons;
  },
};

export default mutations;
