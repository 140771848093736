/* eslint-disable indent */
import BaseService from './BaseService';

// const { cookies } = useCookies({ secure: true });

export default class HpErrorLogService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getAllHpErrorLog(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      console.log(term.searchUser.value);
      if (term.searchUser) {
        apiEndPoint = `${this.baseURL}v1/hp-error-log/?search=${term.searchUser.value}&orderBy=-updated_at&limit=${term.perPageUser.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/hp-error-log/?orderBy=-updated_at&limit=${term.perPageUser.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/storyphones/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

//   storyPhoneUpload(data) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/`;
//       this.http
//         .post(apiEndPoint, data, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   getStoryPhoneDetailsById(id) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/${id}`;
//       this.http
//         .get(apiEndPoint, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   }

//   storyPhoneUpdate(id, data) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/${id}/`;
//       this.http
//         .put(apiEndPoint, data, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   storyPhoneDelete(id) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/${id}/`;
//       this.http
//         .delete(apiEndPoint, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   getAllDeletedStoryPhone(term) {
//     return new Promise((resolve, reject) => {
//       let apiEndPoint;
//       if (term.searchStoryPhone.value) {
//         apiEndPoint = `${this.baseURL}v1/storyphones/?search=${term.searchStoryPhone.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
//       } else {
//         apiEndPoint = `${this.baseURL}v1/storyphones/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
//       }
//       // const apiEndPoint = `${this.baseURL}v1/storyphones/?limit=10&orderBy=-updated_at`;
//       this.http
//         .get(apiEndPoint, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   }

//   restoreStoryPhone(id) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/restore/${id}`;
//       this.http
//         .put(apiEndPoint, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   storyPhoneDownload(data) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/csv-download`;
//       this.http
//         .post(apiEndPoint, data, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   headPhoneBulkUpload(data) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphones/bulk-upload`;
//       this.http
//         .post(apiEndPoint, data, {
//           headers: {
//             // eslint-disable-next-line no-underscore-dangle
//             'Content-Type': 'multipart/form-data;',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }

//   getStoryPhoneModels(data) {
//     return new Promise((resolve, reject) => {
//       const apiEndPoint = `${this.baseURL}v1/storyphoneDetails/`;
//       this.http
//         .get(apiEndPoint, data, {
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${this.getUserToken()}`,
//           },
//         })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error.response);
//         });
//     });
//   }
}
